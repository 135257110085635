import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom"; 

function Footer() {
  return (
    <footer className="bg-blue-600 py-6 md:py-12 px-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
        
        {/* Left Section with Goliath Property */}
        <div className="w-full md:w-1/3 flex flex-col items-center md:items-start space-y-3">
          <h1 className="font-semibold text-[20px] md:text-[26px] text-[#ffffff] text-center md:text-left font-poppins">Goliath Property</h1>

          {/* Social Media Icons */}
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61564968940078" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" className="text-[#ffffff] hover:text-gray-300" />
            </a>
            <a href="https://instagram.com/goliathproperty" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" className="text-[#ffffff] hover:text-gray-300" />
            </a>
          </div>
        </div>

        {/* Center Section - About Us, Privacy, Terms */}
        <div className="w-full md:w-auto text-gray-600 text-center">
        <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4">
      <Link to="/privacy-policy" className="text-[#f0f0f0] hover:text-gray-300 font-poppins">
        Privacy Policy
      </Link>
      <span className="hidden md:block text-[#ffffff] font-poppins">|</span>
      <Link to="/terms-of-service" className="text-[#f0f0f0] hover:text-gray-300 font-poppins">
        Terms of Service
      </Link>
    </div>
          <p className="mt-4 text-[#ffffff] text-sm font-poppins">© Goliath Property 2024</p>
          <a href="https://vizentra.io" aria-label="Vizentra" target="_blank" rel="noopener noreferrer" className="text-[#ffffff] font-poppins text-sm hover:text-gray-300 transition-colors duration-200">
            Built by Vizentra
          </a>
        </div>

        {/* Right Section Placeholder for Flexbox Balance */}
        <div className="w-full md:w-1/3"></div>
      </div>
    </footer>
  );
}

export default Footer;