import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-blue-500 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo or Title */}
        <h1 className="text-white font-poppins font-bold text-2xl md:text-[28px]">Goliath Property</h1>

        {/* Hamburger Icon for mobile */}
        <button
          className="text-gray-900 md:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label={menuOpen ? "Close menu" : "Open menu"} // Dynamically set aria-label
        >
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="lg" />
        </button>


        {/* Navigation Links */}
        <nav
          className={`${menuOpen ? 'block' : 'hidden'
            } md:flex md:items-center space-y-4 md:space-y-0 space-x-0 md:space-x-8 mt-4 md:mt-0 text-center md:text-left`}
        >
          <Link to="/" className="block font-poppins text-gray-900 hover:underline">Home</Link>
          <Link to="/about" className="block font-poppins text-gray-900 hover:underline">About Us</Link>
          <Link to="/upload" className="block font-poppins text-gray-900 hover:underline">Upload</Link>
          <Link to="/properties" className="block font-poppins text-gray-900 hover:underline">Properties</Link>
          <Link
            to="#"
            onClick={() => window.open('https://donate.stripe.com/eVa9Bs9l90dG2AM28i', '_blank')}
            className="block font-poppins text-gray-900 hover:underline"
          >
            Support Us
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;