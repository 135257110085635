import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { ClipLoader } from 'react-spinners';

// Lazy load route components
const Home = React.lazy(() => import('./pages/home'));
const AboutUs = React.lazy(() => import('./components/aboutUs'));
const PropertyListPage = React.lazy(() => import('./pages/propertyListPage'));
const PropertyDetailPage = React.lazy(() => import('./pages/propertyDetailPage'));
const TermsAndConditions = React.lazy(() => import('./components/termsOfService'));
const PrivacyPolicy = React.lazy(() => import('./components/privacyPolicy'));
const NotFound = React.lazy(() => import('./components/notFound'));
const PropertyUpload = React.lazy(() => import('./pages/propertyUpload'))

function App() {
  // Load chat widget
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://widgets.leadconnectorhq.com/loader.js";
    script.setAttribute('data-resources-url', 'https://widgets.leadconnectorhq.com/chat-widget/loader.js');
    script.setAttribute('data-widget-id', '670dd57f54351102ad4b0357');
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      if (script && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  // Load Google Analytics
  useEffect(() => {
    const loadGoogleAnalytics = () => {
      const script1 = document.createElement('script');
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-J8YJCGNS0V';
      script1.async = true;
      document.body.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-J8YJCGNS0V');
      `;
      document.body.appendChild(script2);
    };

    window.addEventListener('load', loadGoogleAnalytics);

    // Cleanup listener
    return () => {
      window.removeEventListener('load', loadGoogleAnalytics);
    };
  }, []);

  return (
    <Router>
      <div className='min-h-screen'>
        <Header />
        <Suspense fallback={<div className="flex justify-center items-center h-screen"><ClipLoader size={50} color="#3498db" /></div>}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path="/properties" element={<PropertyListPage />} />
            <Route path="/properties/:id" element={<PropertyDetailPage />} />
            <Route path="/terms-of-service" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
            <Route path='/upload' element={<PropertyUpload />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
}

export default App;